body {
  margin: 0;
  font-family: 'Poppins-Regular', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#f0f0f0;
  letter-spacing: -0.8px;
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("./asset/font/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("./asset/font/Poppins/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Dongle";
  src: url("./asset/font/Dongle/Dongle-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Fortnite";
  src: url("./asset/font/Fortnite/Fortnite.ttf") format("truetype");
}

@font-face {
  font-family: "Chirp";
  src: url("./asset/font/Chirp/chirp.otf") format("opentype");
}